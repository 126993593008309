import React from 'react';
import cl from 'clsx';
import { ReactComponent as LogoIcon } from 'src/assets/images/oreome-simple.svg';

import styles from './SidebarLogo.module.scss';
import { Typography } from '../Typography';

const SidebarLogo = ({
  collapsed,
  isDarkTheme,
  className,
}: {
  collapsed: boolean;
  isDarkTheme: boolean;
  className?: string;
}) => {
  return (
    <div
      className={cl(
        styles.sidebarLogo,
        collapsed && styles.collapsed,
        isDarkTheme && styles.darkVariant,
        className && className
      )}
    >
      <LogoIcon className={styles.icon} />
      <Typography.H2 weight={600} isUppercase className={styles.text}>
        OREOME
      </Typography.H2>
    </div>
  );
};

export default SidebarLogo;
